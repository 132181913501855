import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import {
  fetchSolr,
  solrResetItems,
  setLastListPath,
  resultSetWord,
} from "../redux/actions/SolrActions"
import { createCategorieBreadCrumb } from "../utils"
import Page from "./Page"
import Results from "./results/Results"
import SubCats from "../components/UI/Categories/SubCats"

const Categorie = ({
  data,
  location,
  items,
  loading,
  dispatch,
  currentLocation,
  city,
  word,
}) => {
  const { categoriesJson: node } = data
  // console.log(node)
  const crumbs = createCategorieBreadCrumb(node)
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      dispatch(resultSetWord(""))
      dispatch(solrResetItems())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && !loading && !items && word === "") {
      if (node.parentTid === "0") {
        dispatch(fetchSolr(false, false, city, node.tid))
      } else {
        dispatch(fetchSolr(false, node.tid, city, false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items, node.tid, node.parentTid, dispatch])

  useEffect(() => {
    if (!loading && items) {
      dispatch(setLastListPath(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items])

  const description =
    "Retrouvez toutes les entreprises du secteur " +
    node.name +
    " en Nouvelle-Calédonie"
  return (
    <Page
      crumbs={crumbs}
      title={node.name}
      location={location}
      description={description}
      filters={node.parentTid !== "0"}
      showCrumbs={true}
    >
      {node.parentTid === "0" && <SubCats />}
      {node.parentTid !== "0" && <Results loading={loading} results={items} />}
    </Page>
  )
}
const mapStateToProps = state => ({
  items: state.solr.items,
  loading: state.solr.loading,
  city: state.solr.city,
  currentLocation: state.global.location,
  word: state.solr.word,
})

export default connect(mapStateToProps)(Categorie)

export const categorieQuery = graphql`
  query CategorieTemplate($url: String!) {
    categoriesJson(url: { eq: $url }) {
      tid
      name
      description
      parentTid
      url
      parentCategorie {
        name
        url
        parentCategorie {
          name
          url
          parentCategorie {
            name
            url
          }
        }
      }
    }
  }
`
